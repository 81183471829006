import React, { useState } from 'react';
import {
  VictoryBar,
  VictoryStack,
  VictoryChart,
  VictoryAxis,
  VictoryLabel,
  VictoryLegend } from 'victory';
import InterestPanel from './components/interestpanel';

import './App.css';

const Page = () => {
  const [investmentData, setInvestmentData] = useState([{period: 0, amount: 0, investmentSum: 0, interestSum: 0}]);
  const [tickValues, setTickValues] = useState([]);
  const [tickFormat, setTickFormat] = useState([]);
  const [timeUnit, setTimeUnit] = useState('');
  const [orderOfMagnitude, setOrderOfMagnitude] = useState(1);
  const [amountSuffixIdx, setAmountSuffixIdx] = useState(0);

  const amountSuffixes = ['', 'K', 'MI', 'BI', 'TR', 'QD'];

  const handleInterestPanelChange = (states) => {
    const newInvestmentData = [];
    const newTickValues = [];
    const newTickFormat = [];

    const initialCapital = Number(states.initialCapital);
    const recurringInvestment = Number(states.recurringInvestment);
    const interestRate = Number(states.interestRate) / 100 + 1;
    const investmentDuration = Number(states.investmentDuration);

    var newOrderOfMagnitude = 1;
    var newAmountSuffixIdx = 0;

    setTimeUnit(states.periodicity === "monthly" ? "Months" : "Years");

    newInvestmentData[0] = {
      period: 0,
      amount: initialCapital,
      investmentSum: initialCapital,
      interestSum: 0
    };
    newTickValues[0] = 0;
    newTickFormat[0] = 0;

    for(let i = 1; i <= investmentDuration; ++i) {
      const initialCapitalResult = Math.pow(interestRate, i) * initialCapital;
      const recurringInvestmentResult = (Math.pow(interestRate, i) - 1) / (interestRate - 1) * recurringInvestment;

      newInvestmentData[i] = {
        period: i,
        amount: initialCapitalResult + recurringInvestmentResult,
        investmentSum: newInvestmentData[i - 1].investmentSum + recurringInvestment,
        interestSum: initialCapitalResult + recurringInvestmentResult - newInvestmentData[i - 1].investmentSum - recurringInvestment
      };
      newTickValues[i] = i;
      newTickFormat[i] = i;

      if(initialCapitalResult + recurringInvestmentResult > newOrderOfMagnitude * 100) {
        newOrderOfMagnitude *= 1000;
        ++newAmountSuffixIdx;
      }
    }

    setInvestmentData(newInvestmentData);
    setTickValues(newTickValues);
    setTickFormat(newTickFormat);
    setOrderOfMagnitude(newOrderOfMagnitude);
    setAmountSuffixIdx(newAmountSuffixIdx);
  };

  return (
    <div className="page-style">
      <div className="interest-panel">
        <InterestPanel onChange={handleInterestPanelChange} />
      </div>
      <div className="chart-style">
        <VictoryChart minDomain={{ x: -0.5 }} maxDomain={{ x: investmentData.length - 0.5 }}>
          <VictoryAxis
            tickValues={tickValues}
            tickFormat={tickFormat}
            label={timeUnit}
            style={{
              axis: { stroke: "Grey" },
              ticks: {stroke: "LightGrey", size: 2},
              axisLabel: { fontSize: 10, padding: 8 },
              tickLabels: { fontSize: 6, padding: 1 },
            }} />
          <VictoryAxis
            dependentAxis
            offsetX={50}
            tickCount={10}
            tickFormat={(x) => (`$ ${x / orderOfMagnitude}${amountSuffixes[amountSuffixIdx]}`)}
            style={{
              axis: { stroke: "Grey" },
              grid: {stroke: "LightGrey"},
              ticks: {stroke: "LightGrey", size: 2},
              tickLabels: { fontSize: 6, padding: 2 } }} />
          <VictoryStack colorScale={["DodgerBlue", "LightGreen"]}>
            <VictoryBar
              data={investmentData}
              barRatio={0.9}
              x="period"
              y="investmentSum" />
            <VictoryBar
              data={investmentData}
              barRatio={0.9}
              cornerRadius={{ topLeft: 1, topRight: 1 }}
              labels={({ datum }) => (`${Math.round(datum.amount / orderOfMagnitude * 10) / 10}`)}
              labelComponent={<VictoryLabel dy={0} />}
              style={{
                labels: { fontSize: 5, fill: "black" },
              }}
              x="period"
              y="interestSum" />
          </VictoryStack>
          <VictoryLegend x={60} y={35}
            orientation="vertical"
            gutter={10}
            style={{
              border: { stroke: "black", fill: "GhostWhite" },
            }}
            data={[
              { name: "Interest Part", symbol: { fill: "LightGreen", stroke: "black", strokeWidth: 0.3, type: "square" }, labels: { fontSize: 6 } },
              { name: "Investment Part", symbol: { fill: "DodgerBlue", stroke: "black", strokeWidth: 0.3, type: "square" }, labels: { fontSize: 6 } },
            ]}
          />
        </VictoryChart>
      </div>
    </div>
  );
};

export default Page;
