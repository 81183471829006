import React, { useState, useEffect } from 'react';
import InputCurrency from './inputcurrency.js';
import InputPercentage from './inputpercentage.js';
import InputIntegerSuffixed from './InputIntegersuffixed.js';

import './interestpanel.css'

const InterestPanel = ({ onChange }) => {
  const [initialCapital, setInitialCapital] = useState('0');
  const [recurringInvestment, setRecurringInvestment] = useState('0');
  const [interestRate, setInterestRate] = useState('0');
  const [investmentDuration, setInvestmentDuration] = useState('0');
  const [periodicity, setPeriodicity] = useState("anually");

  useEffect(() => {
    if(periodicity === "monthly") {
      setRecurringInvestment(recurringInvestment / 12);
      setInterestRate((Math.pow(interestRate / 100.0 + 1.0, 1.0 / 12.0) - 1.0) * 100.0);
      setInvestmentDuration(investmentDuration * 12);
    } else {
      setRecurringInvestment(recurringInvestment * 12);
      setInterestRate((Math.pow(interestRate / 100.0 + 1.0, 12.0) - 1.0) * 100.0);
      setInvestmentDuration(investmentDuration / 12);
    }

    if(onChange) {
        onChange({initialCapital, recurringInvestment, interestRate, investmentDuration, periodicity});
    }
  }, [periodicity]);

  useEffect(() => {
    if(onChange) {
        onChange({initialCapital, recurringInvestment, interestRate, investmentDuration, periodicity});
    }
  }, [initialCapital, recurringInvestment, interestRate, investmentDuration]);

  const handlePeriodicityChange = (event) => {
    var newPeriodicity = event.target.value;

    setPeriodicity(newPeriodicity);
  };

  return (
    <div className="form-container">
      <div className="form-group">
        <label className="form-label">Initial Investment:</label>
        <InputCurrency
          id="initialCapital"
          className="form-input"
          value={initialCapital}
          onChange={setInitialCapital} />
      </div>
      <div className="form-group-radio">
        <label className="form-label" htmlFor="periodicity">Periodicity:</label>
        <label>
          <input
            type="radio"
            name="options"
            value="anually"
            checked={periodicity === 'anually'}
            onChange={handlePeriodicityChange}
          />
          Anually
        </label>
        <label>
          <input
            type="radio"
            name="options"
            value="monthly"
            checked={periodicity === 'monthly'}
            onChange={handlePeriodicityChange}
          />
          Monthly
        </label>
      </div>
      <div className="form-group">
        <label className="form-label">Recurring Investiment:</label>
        <InputCurrency
          id="recurringInvestment"
          className="form-input"
          value={recurringInvestment}
          suffix={periodicity}
          onChange={setRecurringInvestment} />
      </div>
      <div className="form-group">
        <label className="form-label">Interest:</label>
        <InputPercentage
          id="interestRate"
          className="form-input"
          value={interestRate}
          suffix={`% ${periodicity}`}
          onChange={setInterestRate} />
      </div>
      <div className="form-group">
        <label className="form-label">Investment Duration:</label>
        <InputIntegerSuffixed
          id="investmentDuration"
          className="form-input"
          value={investmentDuration}
          suffix={periodicity === "monthly" ? " months" : " years"}
          onChange={setInvestmentDuration} />
      </div>
    </div>
  );
};

export default InterestPanel;
