import React from 'react';
import { NumericFormat } from 'react-number-format';

function InputPercentage({ onChange, suffix = "%", value }) {
  const handleValueChange = (values) => {
    const { value } = values;
    if (onChange) {
      onChange(value);
    }
  };

  return (
    <NumericFormat
      value={value}
      suffix={` ${suffix}`}
      allowedDecimalSeparators={['.', ',']}
      placeholder={`0 ${suffix}`}
      onValueChange={handleValueChange}
    />
  );
}

export default InputPercentage;